.bodyClass{
	text-align: center;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	flex: 1;
}
.footerClass{
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
footer.defaultFooter{
	border-top: 1px solid #eee;
	padding: 4px 12px;
}
footer.buttonCenter{
	display: flex;
	justify-content: center;
}
footer.buttonLeft{
	display: flex;
	justify-content: flex-start;
}
footer.buttonRight{
	display: flex;
	justify-content: flex-end;
}
